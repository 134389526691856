<template>
  <div class="page-face">
    <!-- <div class='face-header'>
           <div class='header-img'></div>
       </div> -->
    <div class="face-main">
      <div class="main-h">确认{{ realName }}本人操作</div>
      <div class="main-text">请保持正脸在取景框中根据屏幕指示完成识别</div>
      <div class="main-img"></div>
    </div>
    <div class="footer-btn" @click="goFace">开始人脸识别</div>
  </div>
</template>
<style lang="less" src="./index.less" scoped></style>
<script>
import { onMounted, toRefs, reactive } from "vue";
import API from "../../server/api";
import { routerTag, dealImage } from "../../utils/index";
import { Toast } from "vant";
import axios from "axios";
import { useStore } from "vuex";
export default {
  setup() {
    const $store = useStore();
    const state = reactive({
      faceToken: "",
      faceReqId: "",
      faceUrl: "",
      realName: "",
      imageBest: "", // 最佳照片
      userId: "",
      creditNo: "",
      liveToken: "",
      token: "",
      ossData:null
    });
    // 活体识别
    const goFace = async() => {
      await getLiveToken();
      await getOss()
      const config={
          requestId: state.faceReqId,
          token: state.liveToken,
          ossData:state.ossData
      }
        // console.log(config,'configs');
      APP.Camera_Live(config, (res) => {
         
          const result=JSON.parse(res)
          //  console.log(result,'resresrrr');
         interFace(result)
          
      })
      
    };

    // 上传到oss
    const getOss = async (file) => {
      // console.log(file,'file');
      const fileKey = "XYQB-FILEURL";
      const res = await API.uploadOss({
        fileName: "jpg",
        fileType: fileKey,
      });
      // const { host, accessId, dir, policy, signature } = res.result;
      state.ossData=res.result
      return false
      const data = new FormData();
      data.append("key", dir);
      data.append("OSSAccessKeyId", accessId);
      data.append("policy", policy);
      data.append("Signature", signature);
      data.append("success_action_status", "200");
      data.append("file", file);
      const baseUrl = host;
      axios({
        url: baseUrl,
        method: "post",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          if (res.status === 200) {
            const fileUrl = baseUrl + dir;
            console.log(fileUrl,'fileUrl')
            // interFace(fileUrl);
          }
        })
        .catch((e) => {
          console.log(e,"失败");
        });
    };
    //获取用户姓名
    const getName = async () => {
      const userName = await API.getRealName();
      state.realName = userName.realName;
    };
    //获取人脸token
    const getLiveToken = async () => {
      const res = await API.getliveToken();
      if (res.code == 200) {
        console.log(res,'tokenid')
        const {bizToken,requestId,channel}=res.result
        state.liveToken = bizToken
        state.faceReqId =requestId
      } else {
        Toast("请稍后再试！");
      }
    };
    const getNewFormDate = () => {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var srtDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (srtDate >= 1 && srtDate <= 9) {
        srtDate = "0" + srtDate;
      }
      var currentdate = year + month + srtDate;
      return currentdate;
    };

    const base64ToFile = (data) => {
      try {
        let arr = data.split(',')
        let mime = arr[0].match(/:(.*?);/)[1]
        let bytes = atob(arr[1]) // 解码base64
        let n = bytes.length
        let ia = new Uint8Array(n)
        while (n--) {
          ia[n] = bytes.charCodeAt(n)
        }
        return new File([ia], `${new Date().getTime()}.jpg`, { type: mime })
      } catch (err) {
        console.log(err, 'Base64转图片失败')
      }
    }
    const interFace = async (fileUrl) => {
     
      // 上传到oss后拿到的路径
      const faceResult = await API.liveBody({
        fileUrl: fileUrl, // 最佳人脸照片URL
        token: state.liveToken, // 全景照片URL
        userId: state.userId, // userId
        creditNo: state.creditNo, // 授信单号
      });
      // console.log(faceResult, 98);
      if (faceResult.code == '200') {
        $store.state.faceSuccess = true;
        Toast.success(faceResult.result);
        setTimeout(function () {
          APP.Back(1)
        }, 800);
      } else {
        Toast.fail(faceResult.result);
      }
    };

    onMounted(() => {
      getName();
      state.userId = localStorage.getItem("userId");
      state.creditNo = localStorage.getItem("creditNo");
      // APP.SET_TITLE("活体检测");
      // state.realName = localStorage.getItem('authName')
    });
    return {
      ...toRefs(state),
      goFace,
      getOss,
    };
  },
};
</script>
